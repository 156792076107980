<template>
  <div class="home">
    <custom-section class="section pa-0 pa-md-5">
      <NavbarOne
        class="d-md-none d-lg-none"
        :showNavbar="showNavbar"
        :showCart="showCart"
        :showCartDesktop="showCartDesktop"
        :showSearch="showSearch"
        :showLogo="showLogo"
      >
      </NavbarOne>
      <div class="mb-6 corp-m">
        <div class="payment-info">
          <v-data-table
            :options.sync="options"
            :headers="headers"
            :items="datas"
            class="table-master-employee pa-3"
            :mobile-breakpoint="0"
            hide-default-footer
            :loading="myloadingvariable"
            :sort-desc="[true]"
            :items-per-page="-1"
          >
            <template v-slot:top>
              <v-row
                class="d-flex flex align-center justify-space-between px-0 py-0"
              >
                <v-col cols="12" md="8" class="d-flex align-center pl-6">
                  <localized-link to="/company/employee/master/add">
                    <v-btn color="primary" dark class="button">
                      {{ $t("add") }}
                    </v-btn>
                  </localized-link>
                </v-col>
                <v-col cols="12" md="4" class="d-flex align-center pr-0">
                  <v-text-field
                    class="searchField fontSet pl-3 pr-3"
                    outlined
                    dense
                    hide-details
                    v-model="search"
                    :label="$t('search') + ' ...'"
                    append-icon="mdi-magnify"
                  ></v-text-field>

                  <div>
                    <v-select
                      class="footerHide"
                      v-model="perPage"
                      :items="itemShowing"
                    ></v-select>
                  </div>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item="{ item }">
              <tr class="hd-sticky">
                <td valign="top" class="pt-2 sticky-c1">
                  {{ item.employee_number }}
                </td>
                <td valign="top" class="pt-2 sticky-c2">
                  <p
                    class="cursor-pointer blue-font-underlline"
                    @click="detailsItem(item)"
                  >
                    {{ item.name }}
                  </p>
                </td>

                <td valign="top" class="pt-2">{{ item.citizenship_name }}</td>
                <td valign="top" class="pt-2">
                  {{ $fnc.replaceSpecialChars(item.department) }}
                </td>
                <td valign="top" class="pt-2">{{ item.job_position }}</td>
                <td valign="top" class="pt-2">{{ item.employee_level }}</td>
                <td valign="top" class="pt-2">{{ item.identity_type }}</td>
                <td valign="top" class="pt-2">{{ item.identity_number }}</td>
                <td valign="top" class="pt-2">{{ item.phone_number }}</td>
                <td valign="top" class="pt-2">
                  {{ item.email.toUpperCase() }}
                </td>
                <td valign="top" class="pt-2">
                  {{ formatDate(item.date_of_birth) }}
                </td>
              </tr>
            </template>
            <template v-slot:footer>
              <v-row
                class="d-flex flex align-center justify-space-between footer-payment"
              >
                <v-col cols="12" md="8">
                  <p class="footer-showing">
                    showing {{ itemShowStart }} to {{ itemShowStop }} of
                    {{ itemsTotal }} entries
                  </p>
                </v-col>
                <v-col cols="12" md="4" class="d-flex justify-end">
                  <custom-pagination
                    @changePage="val => (page = val)"
                    :totalPage="itemLength"
                    :activePage="page"
                  />
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </div>
      </div>
    </custom-section>
  </div>
</template>

<script>
// import TabContent from "@/components/v2/tabs/index.vue";
import API from "@/service/api_service.js";
import Auth from "@/service/auth_service";
import moment from "moment";
import NavbarOne from "@/components/v2/topbar/navbar_one.vue";
import CustomPagination from "@/components/v3/pagination.vue";
import CustomSection from "@/components/v2/section/index.vue";

export default {
  components: {
    NavbarOne,
    CustomPagination,
    CustomSection,
  },
  data: () => ({
    page: 1,
    options: null,
    itemsTotal: 0,
    itemLength: 1,
    itemShowStart: 0,
    itemShowStop: 0,
    itemShowing: [
      { value: 25, text: "25" },
      { value: 50, text: "50" },
      { value: 70, text: "70" },
      { value: 100, text: "100" },
    ],

    perPage: 25,
    myloadingvariable: true,
    showNavbar: true,
    showCart: false,
    showCartDesktop: false,
    showSearch: false,
    showLogo: true,
    search: "",
    dialogDelete: false,
    formHasError: false,
    headers: [],
    employeeList: [],
    datas: [],
    dialog_employee: false,
    editedIndex: -1,
    employee_number: null,
    identity_type: null,
    name: null,
    department: null,
    identity_number: null,
    phone_number: null,
    email: null,
    date_of_birth: null,
    insurance_number: null,
    insurance_expired_at: null,
    employeeDetailData: [],
    errorMessages: "",
    defaultItem: {
      employee_number: "",
      identity_type: "",
      name: "",
      department: "",
      identity_number: "",
      phone_number: "",
      email: "",
      date_of_birth: "",
      departagement: "",
      insurance_number: "",
      insurance_expired_at: "",
    },
    selectData: [],
    dialog_order: false,
    dataDialogOrder: {
      order_id: null,
      list: [
        {
          document_information: {
            number_of_report: null,
            medical_record_number: null,
            datetime_observation: null,
            gender: null,
            plant: null,
            primary_doctor: null,
            lab_chief: null,
          },
          customers_information: {
            patient_name: null,
            address: null,
            city: null,
            province: null,
            postal_code: null,
            country: null,
            dob: null,
            patient_age: null,
          },
          product: {
            product_id: null,
            product_code: null,
            product_name: null,
          },
        },
      ],
    },
    idTemp: "",
  }),
  created() {
    this.headers = [
      // {
      //   text: this.$t("page.profile.actions"),
      //   value: "actions",
      //   sortable: false,
      //   width: "100px",
      //   fixed: true,
      //   class: "sticky-h1",
      //   align: "center",
      // },
      {
        text: this.$t("field.employee_number"),
        sortable: false,
        value: "employee_number",
        fixed: true,
        class: "sticky-h1",
        align: "center",
        width: "155px",
      },
      {
        text: this.$t("field.name"),
        value: "name",
        sortable: true,
        fixed: true,
        class: "sticky-h2",
        align: "center",
      },
      {
        text: this.$t("field.citizenship"),
        value: "citizenship_name",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("field.department"),
        value: "department",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("field.jobpost"),
        value: "job_position",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("field.level"),
        value: "employee_level",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("field.identity_type"),
        sortable: false,
        value: "identity_type",
        align: "center",
      },
      {
        text: this.$t("field.id_number"),
        value: "identity_number",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("field.mobile_phone"),
        value: "phone_number",
        sortable: false,
      },
      { text: this.$t("field.email"), value: "email", sortable: false },
      {
        text: this.$t("field.dobirth"),
        value: "date_of_birth",
        sortable: false,
        align: "center",
      },
    ];
    this.getDataEmployee();
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    page: function () {
      this.getDataEmployee();
    },
    perPage: function () {
      if (this.page != 1) {
        this.page = 1;
      } else {
        this.getDataEmployee();
      }
    },
    search: function () {
      this.page = 1;
      this.getDataEmployee();
    },
  },

  methods: {
    // async detailItem(item) {
    //   this.getInitialEmployee(item.id);
    //   this.getInitialRevenuePopup(item.id);
    //   this.dialog_employee = true;
    // },
    formatDate(date) {
      if (!date) return null;
      return moment(date)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
    toast(status, messages) {
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        })
        .fire({
          icon: status,
          title: messages,
        });
    },
    detailsItem(item) {
      this.$router.push(
        `/${this.$i18n.locale}/company/employee/master/detail/${item.id}`
      );
    },

    async getDataEmployee() {
      // console.log(`${Auth.getToken()}`);
      // this.$store.commit("setLoading", true);
      try {
        this.myloadingvariable = true;
        // pagination showing start of entries
        let startPage = (this.page - 1) * this.perPage;
        let showPage = this.perPage;
        this.itemShowStart = startPage + 1;
        this.itemShowStop = startPage + showPage;

        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}employee/get?query=${this.search}&offset=${startPage}&per_page=${showPage}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        // Check result exist
        if (res.results) {
          var new_data = [];
          var checkData = res.results.filter(res => {
            return res.employee_number != null;
            // return res.employee_number != null && res.identity_type == "ID CARD NUMBER";
          });
          checkData.map(function (e) {
            var birthdate =
              e.date_of_birth !== null && e.date_of_birth
                ? moment(e.date_of_birth).format("DD-MMM-YYYY").toUpperCase()
                : "-";
            // var expired =
            //   e.insurance_expired_at !== null && e.insurance_expired_at
            //     ? moment(e.insurance_expired_at)
            //         .format("DD-MMM-YYYY")
            //         .toUpperCase()
            //     : "-";
            new_data.push({
              id: typeof e.id !== "undefined" ? e.id : "",
              identity_type:
                e.identity[0] !== null
                  ? e.identity[0].identity_type.toUpperCase()
                  : "-",
              employee_number:
                typeof e.employee_number !== "undefined"
                  ? e.employee_number.toUpperCase()
                  : "-",
              name: typeof e.name !== "undefined" ? e.name.toUpperCase() : "",
              citizenship_name:
                e.citizenship_name !== null
                  ? e.citizenship_name.toUpperCase()
                  : "-",
              department:
                e.department !== null ? e.department.toUpperCase() : "-",
              job_position:
                e.job_position !== null ? e.job_position.toUpperCase() : "-",
              employee_level:
                e.employee_level !== null ? e.employee_level : "-",
              identity_number:
                e.identity[0] !== null ? e.identity[0].identity_number : "-",
              phone_number:
                typeof e.phone_number !== "undefined" ? e.phone_number : "-",
              email:
                typeof e.email !== "undefined" ? e.email.toUpperCase() : "-",
              date_of_birth: birthdate,
              // insurance_number:
              //   e.insurance_number !== null
              //     ? e.insurance_number.toUpperCase()
              //     : "-",
              // insurance_expired_at: expired,
            });
          });

          this.datas = new_data;
          this.selectData = checkData;

          this.itemsTotal = res.total;
          this.itemLength = Math.ceil(this.itemsTotal / showPage);
          if (this.itemShowStop < 0) {
            this.itemShowStop = res.total;
          }
          if (this.itemShowStop > res.total) {
            this.itemShowStop = res.total;
          }
          this.myloadingvariable = false;
        }
      } catch (error) {
        console.log(error);
      }
      // this.$store.commit("setLoading", false);
    },
  },
};
</script>
<style>
@media screen and (min-width: 1023px) {
  .sticky-c1 {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;

    background: #fff;
  }
  .sticky-c2 {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 155px;

    background: #fff;
  }
  .sticky-h1 {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 5;
    background: #ddd;
  }
  .sticky-h2 {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 155px;
    z-index: 5;
    background: #ddd;
  }
}
.table-master-employee thead {
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
}
.table-master-employee thead tr th {
  height: 38px !important;
  white-space: nowrap;
}
.table-master-employee tbody tr td {
  font-size: 12px !important;
  height: 35px !important;
  white-space: nowrap;
}
.table-master-employee tbody tr:hover {
  background-color: #eee !important;
}
</style>
<style lang="scss" scoped>
@import "@/assets/css/custom.scss";
.home {
  display: flex;
  flex-direction: column;
  padding: 1px 0 40px 0;
  &__section {
    padding: 0 20px;
  }
}
@media screen and (min-width: 1023px) {
  .hd-sticky:hover {
    .sticky-c1 {
      background: #eee !important;
    }
    .sticky-c2 {
      background: #eee !important;
    }
    .v-data-table
      > .v-data-table__wrapper
      tbody
      tr:first-child:hover
      td:first-child {
      background: #eee !important;
    }
  }
}
.searchField {
  max-width: 500px;
}
.button--active {
  background: $main_3;
  color: white;
}
.button--outline {
  color: $main_3;
  border: 1px solid $main_3;
  background: white;
}
.button--download {
  background: #79c4c6;
  color: white;
}
.button-box {
  box-sizing: border-box;
  border-radius: 10px;
  padding: 8px 16px;
  outline: none;
}
.card--result {
  background: $gray_3;
  padding: 18px;
  border-radius: 16px;
  width: 100%;
  // max-width: 300px;
  color: $gray_2;
  h4 {
    color: $gray_1;
    font-size: 20px;
  }
  p {
    margin: 0;
  }
  .card--result__item {
    padding: 6px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $gray_2;
    &:nth-last-child(1) {
      border: none;
    }
  }
}

.status--inprogress {
  color: $yellow_1;
  font-weight: 600;
}
.status--complete {
  color: $green_1;
  font-weight: 600;
}
.status--cancel {
  color: $red_1;
  font-weight: 600;
}
.tabs {
  background: #e5e5e5 !important;
}
.content-table {
  padding: 15px;
  @media screen and (max-width: 960px) {
    padding: 5px 18px 18px 18px;
  }
}
@media screen and (max-width: 480px) {
  .content-table {
    padding: 0px !important;
  }
}
.fontSet {
  font-size: 14px !important;
}
.button {
  background-color: #00a4b3 !important;
  border-radius: 5px;
  color: white !important;
  font-size: 14px;
  text-align: center;
  text-transform: capitalize;
  height: 30px !important;
  min-width: 100px !important;
}
a {
  text-decoration: none;
}
.fontSet {
  font-size: 14px !important;
}
.box-page-new {
  margin: 20px;
  border-radius: 4px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  vertical-align: baseline;
  padding-top: 6px;
  padding-bottom: 6px;
  height: 25px !important;
}

.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  height: 35px !important;
}
.button-edit-employee {
  background-color: #00a4b3 !important;
  color: white;
  margin-right: 7px;
}
.button-delete-employee {
  background-color: #eefdfe !important;
  color: #00a4b3;
  margin-left: 7px;
}
.title-detail {
  color: #1a428a;
}
.text-title {
  font-size: 12px;
  text-transform: uppercase;
  color: #00a4b3;
  font-weight: bold;
}
.text-new-title {
  font-size: 12px;
  text-transform: uppercase;
  color: #000;
  font-weight: bold;
}
.text-new-value {
  font-size: 12px;
  text-transform: uppercase;
  color: #000;
}
.text-value {
  font-size: 12px;
  text-transform: uppercase;
  color: #000;
  text-align: right;
}
.title-information {
  font-size: 16px;
}
.list-data {
  padding: 12px 8px 0 8px !important;
}
.cursor-pointer {
  cursor: pointer;
}
.blue-font-underlline {
  color: #1976d2;
  text-decoration: underline;
}
</style>
<style>
.table-employee-company thead {
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
}
.table-employee-company thead tr th {
  height: 38px !important;
  white-space: nowrap;
}
.table-employee-company tbody tr td {
  font-size: 12px !important;
  height: 35px !important;
  white-space: nowrap;
}
.table-employee-company tbody tr:hover {
  background-color: #eee !important;
}
.table-lists {
  padding: 4px 16px 4px 16px;
}
</style>
<style lang="scss">
.footerHide {
  background: none !important;
  align-items: center;
  width: 50px;
  font-size: 12px;
}
.footerHide > .v-data-footer__pagination {
  display: none !important;
}
.footerHide > .v-data-footer__icons-before {
  display: none !important;
}
.footerHide > .v-data-footer__icons-after {
  display: none !important;
}
.payment-info .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  vertical-align: baseline;
  padding-top: 6px;
  padding-bottom: 6px;
}
.v-toolbar__title {
  font-size: 16px;
}
.footer-payment {
  margin: 0 auto !important;
}
.footer-showing {
  font-size: 12px !important;
  margin: 0 auto !important;
}

.footerHide > .v-text-field .v-input__prepend-inner,
.v-text-field .v-input__append-inner {
  padding-left: 0 !important;
}
</style>
<style>
/* Hide scrollbar for Chrome, Safari and Opera */
.table-stick-master-employee .v-data-table__wrapper::-webkit-scrollbar {
  height: 8px;
  width: 0px;
  border: 1px solid #fff;
}

.table-stick-master-employee .v-data-table__wrapper::-webkit-scrollbar-track {
  border-radius: 0;
  background: #eeeeee;
}

.table-stick-master-employee .v-data-table__wrapper::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #b0b0b0;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table-stick-master-employee .v-data-table__wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.super-dense > .v-data-table__wrapper > table > tbody > tr > td,
.super-dense > .v-data-table__wrapper > table > tbody > tr > th,
.super-dense > .v-data-table__wrapper > table > thead > tr > td,
.super-dense > .v-data-table__wrapper > table > thead > tr > th,
.super-dense > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 35px !important;
}
.table-stick-master-employee .v-data-table__wrapper {
  overflow-x: auto;
  overflow-y: auto;
  height: 400px !important;
}
.table-stick-master-employee thead {
  position: sticky;
  inset-block-start: 0;
  top: 0;
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
  z-index: 5;
}
</style>
